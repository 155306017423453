import React from 'react'
import Sidebar from './Sidebar';
import Header from './Header';

const BaseLayout = (props) => {
  const { modules, 
    ChildComponent,
    handleSidebar,
    isOpen,
    username,
    handleUsername,
    is_photo_loading,
    is_common_loading,
    is_activity_loading,
    is_home_loading,
    currentPage
  } = props;

  const ToggleSidebar = () => {
      isOpen === true ? handleSidebar(false) : handleSidebar(true);
  }
  return (
    <div className='w-full h-full flex flex-col relative'>
        <Header 
          ToggleSidebar = {ToggleSidebar} 
          username = {username}
          handleUsername = {handleUsername}
          currentPage = {currentPage}
        />
        <Sidebar
          ToggleSidebar = {ToggleSidebar}
          isOpen = {isOpen}
          modules = {modules}
        />
        <main className="md:mt-0 mt-[10.5rem] mx-auto h-full w-full">
          <ChildComponent />
        </main>
        {
          (is_photo_loading || is_common_loading || is_activity_loading || is_home_loading) &&
          <div className='fixed left-0 top-0 w-full h-full bg-[rgba(0,0,0,0.6)] overflow-hidden z-50 select-none flex justify-center items-center'>
            <img className='w-16' src='/images/orbus-loader.gif' alt='orbus loader'/>
          </div>
        }
    </div>
  )
}

export default BaseLayout;