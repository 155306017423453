import React, { useEffect, useState, useRef } from 'react'
import Quagga from 'quagga';
import { parser_get_bc_order_details } from '../../store/home/homeParser';
import axios from 'axios';
import { GET_BC_ORDER_DETAILS } from '../../store/home/homeConstants';
import { baseUrl } from '../../helpers/constants';
import { useDispatch } from 'react-redux';
import { setCommonLoading, setReferenceNo } from '../../store/common/commonSlice';
import { useNavigate } from 'react-router-dom';


let lastDetectedBarcode = null;
let detectionTimestamp = 0;
const Scan = (props) => {
  const { handleOrderDetails } = props;
  const dispatch = useDispatch();
  const [restart, setRestart] = useState(false)
  const navigate = useNavigate();

  const stopReader = () => {
    Quagga.stop()
  }



  const getOrientation = () =>
    window.screen.orientation.type

  const useScreenOrientation = () => {
    const [orientation, setOrientation] =
      useState(getOrientation())

    const updateOrientation = event => {
      setOrientation(getOrientation())
    }

    useEffect(() => {
      window.addEventListener(
        'orientationchange',
        updateOrientation
      )
      return () => {
        window.removeEventListener(
          'orientationchange',
          updateOrientation
        )
      }
    }, [])

    return orientation
  }
  useEffect(() => {
    setRestart(false);
    dispatch(setCommonLoading(true));
    Quagga.init({
      inputStream: {
        name: "Live",
        type: "LiveStream",
        target: document.querySelector('#interactive'),
        constraints: {
          width: 660,
          height: 660
        },
        locator: {
          patchSize: "Medium",
          halfSample: true
        },


      },
      decoder: {
        readers: ["code_39_reader"]
      },
      // locate : true,
    }, function (err) {
      if (err) {
        alert(err.message)
        dispatch(setCommonLoading(false));
        navigate('/');
        return
      }
      var track = Quagga.CameraAccess.getActiveTrack();
      var capabilities = {};
      let advanceConstraintsList = []
      if (typeof track.getCapabilities === 'function') {
        capabilities = track.getCapabilities();
        console.log(capabilities)

      }

      if ('focusMode' in capabilities) {
        advanceConstraintsList = [...advanceConstraintsList, { focusMode: "single-shot" }]
      }
      if ('stabilizationMode' in capabilities) {
        advanceConstraintsList = [...advanceConstraintsList, { stabilization: 'auto' }]
      }

      track.current?.applyConstraints({ advanced: advanceConstraintsList })

      dispatch(setCommonLoading(false));
      console.log("Initialization finished. Ready to start");
      Quagga.start()




      // const drawOverlay = () => {
      //   const drawingCtx = Quagga.canvas.ctx.overlay;
      //   const drawingCanvas = Quagga.canvas.dom.overlay;
      //   const  tstContex = Quagga.canvas.ctx
      //   // Set the 'willReadFrequently' attribute for better performance
      //   if (drawingCanvas) {
      //     drawingCanvas.willReadFrequently = true; // Inform the browser for frequent reads
      //   }
      //   if (drawingCtx && drawingCanvas) {
      //     drawingCtx.strokeStyle = 'rgba(0, 255, 0, 0.8)'; // Green box
      //     drawingCtx.lineWidth = 3;
      //     let tesInteractiveElement = document.getElementById('interactive');
      //     if (tesInteractiveElement) {

      //       const testHeight = tesInteractiveElement.offsetHeight;
      //       const testWidth = tesInteractiveElement.offsetWidth;
      //       // Get actual canvas size (matches video feed)
      //       const canvasWidth = testWidth;
      //       const canvasHeight = testHeight;

      //       // Adjust based on patchSize (change multiplier as needed)
      //       const patchWidth = canvasWidth * 0.5;
      //       const patchHeight = canvasHeight * 0.4;

      //       // Center the patch
      //       const startX = (canvasWidth - patchWidth) / 2;
      //       const startY = (canvasHeight - patchHeight) / 2;

      //       // Draw border
      //       drawingCtx.strokeRect(startX, startY, patchWidth, patchHeight);
      //     }

      //   }
      //   requestAnimationFrame(drawOverlay);
      // }
      // requestAnimationFrame(drawOverlay);
      return () => {

        Quagga.stop()

      }

    });


    const callOrderDetailsAPI = async (params) => {
      const response = await axios.get(baseUrl + GET_BC_ORDER_DETAILS, { params }).then(async (response) => {
        const result = response.data;
        const paresedata = await parser_get_bc_order_details(result);
        handleOrderDetails(paresedata);
        setRestart(false);
        return response.data;
      }).catch((err) => {
        dispatch(setCommonLoading(false));
        alert(err?.response?.data?.detail || err.detail || "Something went wrong!");
        setRestart(true);
        return;
      });
    }

    const detected = async (data) => {
      const newCode = data.codeResult.code;
      const currentTime = Date.now();
      if (currentTime - detectionTimestamp < 50) {
        return;
      }
      detectionTimestamp = currentTime;
      if (lastDetectedBarcode !== newCode) {
        lastDetectedBarcode = newCode;
        stopReader()
        dispatch(setCommonLoading(true));
        let params = {
          order_no: newCode
        }
        dispatch(setReferenceNo(newCode));
        callOrderDetailsAPI(params);
      }
      else {
        lastDetectedBarcode = null
      }
    }
    Quagga.onDetected(detected);
  }, [restart, handleOrderDetails, dispatch, navigate]);

  useEffect(() => {
    const interactiveElement = document.getElementById('interactive');

    if (interactiveElement) {
      const bufferCanvas = interactiveElement.getElementsByClassName('drawingBuffer');

      if (bufferCanvas.length > 0) {
        const bufferCanvasElement = bufferCanvas[0];

        // Remove display: none
        bufferCanvasElement.style.display = "block";

        // Apply position: absolute
        bufferCanvasElement.style.position = "absolute";


      }
    }

  })

  return (
    <div className="flex flex-col justify-center items-center flex-1 h-full">
      
      <div
        className="flex flex-4 bg-black  h-96 w-96 overflow-hidden relative"
        id="interactive"
      >
        <div className='border-4 border-green-600 absolute w-48 h-40 top-28 left-24'>

        </div>

      </div>
      <div className="flex flex-col justify-center  items-center flex-none h-24 bg-black text-white">
        <p className="mb-0.5 text-sm md:text-2xl lg:text-sm">Scanning...</p>

      </div>
    </div>
  );
}

export default React.memo(Scan);
