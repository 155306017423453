import React, { useEffect } from 'react';
import { 
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnLink,
  Editor,
  EditorProvider,
  Toolbar
} from 'react-simple-wysiwyg';
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom';

const ActivityForm = (props) => {
  const {handleCurrentPage,
    callUseCaseList,
    use_case_list,
    handleCreateActivity,
    is_edit,
    activity_details,
    handleEditActivity
  } = props;

  const navigate  = useNavigate();
  let tmpActivityDetails = null;
  let EMAIL_REGX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const validationSchema = yup.object().shape({
    use_case : yup.string().required('please select use case.'),
    from_email : yup.string().matches(EMAIL_REGX, "Invalid email address").required('please enter from email.').trim(),
    email_to : yup.string().required('please enter email to.').trim(),
    name : yup.string().required('please enter activity name.').trim(),
    subject : yup.string().required('please enter email subject.').trim(),
    // signature_url : yup.string().matches(/\.(.png|.jpg|.jpeg)$/, 'Invalid signature url.').trim(),
    signature_url : yup.string().nullable().trim(),
    email_body : yup.string().required('please enter email body.').trim(),
  });

  const onSubmit = async (values) => {
    if (is_edit) {
      var editRes = await handleEditActivity(values);
      if (!editRes.error){
        await handleCurrentPage('/activities');
        navigate('/activities');
      }
    }
    else {
      var createRes = await handleCreateActivity(values);
      if (!createRes.error){
        await handleCurrentPage('/activities');
        navigate('/activities');
      }
    }
  };
  if (is_edit){
    tmpActivityDetails = {...activity_details};
    delete tmpActivityDetails.display_order;
    delete tmpActivityDetails.created_by;
    tmpActivityDetails.use_case = activity_details.activity_usecases.id;
    delete tmpActivityDetails.activity_usecases;
  }
  const formik = useFormik({
    enableReinitialize : true,
    initialValues : !is_edit ? {
      is_active : true,
      bc_update : false,
      use_case : "",
      from_email : "",
      email_to : "",
      email_cc:"",
      name : "",
      subject : "",
      signature_url : "",
      email_body : ""
    } : tmpActivityDetails,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.email_body =
        values.email_body.includes("{{photo_link}}") === false
          ? values.email_body.replace("photo_link", "{{photo_link}}")
          : values.email_body;
 
      let tmpActivity = {...values};
      tmpActivity.display_order = 1;
      if (is_edit){
        delete tmpActivity.name;
        delete tmpActivity.use_case;
      }
      onSubmit(tmpActivity);
    }
  });

  const handleCancel = async () => {
    await handleCurrentPage('/activities');
    navigate('/activities');
  }

  useEffect(() => {
    callUseCaseList();
  },[callUseCaseList]);

  return (
    <div className="space-y-1 leading-10">
      <div className="bg-[#303030] rounded-lg text-center text-white text-sm md:text-2xl lg:text-xl py-1 font-medium">
        {is_edit ? "Edit" : "Create"} Activity
      </div>
      <form className="space-y-1" onSubmit={formik.handleSubmit}>
        <div className="rounded-lg bg-[#303030] px-3 flex justify-between items-center space-x-2">
          <div className="flex-1">
            <label className="text-[#858182] text-sm md:text-2xl lg:text-xl font-medium">
              Use Case
            </label>
            <select
              className="py-0.5 pe-5 block w-full md:w-3/4 bg-[#757575] text-black border-transparent rounded-sm text-sm md:text-2xl lg:text-xl focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              name="use_case"
              value={formik.values.use_case}
              onChange={formik.handleChange("use_case")}
              onBlur={formik.handleBlur("use_case")}
              {...(is_edit && { disabled: true })}
            >
              <option
                value=""
                className="text-black bg-red-gray-300 text-sm md:text-xl lg:text-xl"
                disabled
              >
                Select use case
              </option>
              {use_case_list.map((use_case, idx) => {
                return (
                  <option
                    value={use_case.id}
                    key={idx}
                    className="text-sm md:text-xl lg:text-xl"
                  >
                    {use_case.name}
                  </option>
                );
              })}
            </select>
            <p className="text-red-600 text-start text-sm md:text-2xl lg:text-xl">
              {formik.touched.use_case && formik.errors.use_case}
            </p>
          </div>
          <div className="flex justify-center flex-col items-center">
            <label className="text-[#858182] text-sm md:text-2xl lg:text-xl  font-medium mt-2">
              Is Active
            </label>
            <div>
              <input
                type="checkbox"
                name="is_active"
                checked={formik.values.is_active}
                value={formik.values.is_active}
                onChange={formik.handleChange("is_active")}
                className="bg-[#757575] w-5 h-5 checked:accent-gray-500 mt-1 cursor-pointer"
              ></input>
            </div>
          </div>
          <div className="flex justify-center flex-col items-center ">
            <label className="text-[#858182] text-sm md:text-2xl lg:text-xl  font-medium mt-2 md:ml-8">
              BC Update?
            </label>
            <div>
              <input
                type="checkbox"
                name="bc_update"
                checked={formik.values.bc_update}
                value={formik.values.bc_update}
                onChange={formik.handleChange("bc_update")}
                className="bg-[#757575] w-5 h-5 checked:accent-gray-500 mt-1 cursor-pointer"
              ></input>
            </div>
          </div>
        </div>
        <div className="rounded-lg bg-[#303030] pb-1 px-3 lg:!mt-1">
          <div>
            <label className="text-[#858182] text-sm md:text-2xl lg:text-xl font-medium">
              From Email
            </label>
            <div>
              <input
                type="text"
                name="from_email"
                value={formik.values.from_email}
                onChange={formik.handleChange("from_email")}
                onBlur={formik.handleBlur("from_email")}
                className="bg-[#757575] py-0.5 px-2 block w-full border-gray-200 rounded-sm text-sm md:text-2xl lg:text-xl  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              ></input>
              <p className="text-red-600 text-start text-sm md:text-2xl lg:text-xl ">
                {formik.touched.from_email && formik.errors.from_email}
              </p>
            </div>
          </div>
        </div>
        <div className="rounded-lg bg-[#303030] pb-1 px-3 lg:!mt-1">
          <div>
            <label className="text-[#858182] text-sm md:text-2xl lg:text-xl font-medium">
              Email To
            </label>
            <div>
              <input
                type="text"
                name="email_to"
                value={formik.values.email_to}
                onChange={formik.handleChange("email_to")}
                onBlur={formik.handleBlur("email_to")}
                className="bg-[#757575] py-0.5 px-2 block w-full border-gray-200 rounded-sm text-sm md:text-2xl lg:text-xl  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              ></input>
              <p className="text-red-600 text-start text-sm md:text-2xl lg:text-xl ">
                {formik.touched.email_to && formik.errors.email_to}
              </p>
            </div>
          </div>
        </div>
        <div className="rounded-lg bg-[#303030] pb-1 px-3 lg:!mt-1">
          <div>
            <label className="text-[#858182] text-sm md:text-2xl lg:text-xl font-medium">
              Email CC
            </label>
            <div>
              <input
                type="text"
                name="from_email"
                value={formik.values.email_cc}
                onChange={formik.handleChange("email_cc")}
                onBlur={formik.handleBlur("email_cc")}
                className="bg-[#757575] py-0.5 px-2 block w-full border-gray-200 rounded-sm text-sm md:text-2xl lg:text-xl  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              ></input>
              <p className="text-red-600 text-start text-sm md:text-2xl lg:text-xl ">
                {formik.touched.email_cc && formik.errors.email_cc}
              </p>
            </div>
          </div>
        </div>
        <div className="rounded-lg bg-[#303030] pt-1 pb-2 px-3 flex flex-col space-y-2 lg:!mt-1">
          <span className="text-gray-400 text-sm md:text-2xl lg:text-xl">
            Activity
          </span>
          <div className="flex space-x-4">
            <label className="text-white text-xs md:text-xl lg:text-xl font-medium">
              Enter Activity
            </label>
            <div className="flex-1">
              <input
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange("name")}
                onBlur={formik.handleBlur("name")}
                {...(is_edit && { disabled: true })}
                className="bg-[#757575] py-0.5 px-2 block w-full border-gray-200 rounded-sm text-sm md:text-2xl lg:text-xl  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              ></input>
              <p className="text-red-600 text-start text-xs md:text-2xl lg:text-xl">
                {formik.touched.name && formik.errors.name}
              </p>
            </div>
          </div>
          <div className="flex space-x-4">
            <label className="text-white text-xs md:text-xl lg:text-xl font-medium">
              Email Subject
            </label>
            <div className="flex-1">
              <input
                type="text"
                name="subject"
                value={formik.values.subject}
                onChange={formik.handleChange("subject")}
                onBlur={formik.handleBlur("subject")}
                className="bg-[#757575] py-0.5 px-2 block w-full border-gray-200 rounded-sm text-sm md:text-2xl lg:text-xl focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              ></input>
              <p className="text-red-600 text-start text-xs md:text-2xl lg:text-xl">
                {formik.touched.subject && formik.errors.subject}
              </p>
            </div>
          </div>
          <div className="flex space-x-4">
            <label className="text-white text-xs md:text-xl lg:text-xl font-medium">
              Signature Url
            </label>
            <div className="flex-1">
              <input
                type="text"
                name="signature_url"
                value={formik.values.signature_url}
                onChange={formik.handleChange("signature_url")}
                onBlur={formik.handleBlur("signature_url")}
                className="bg-[#757575] py-0.5 px-2 block w-full border-gray-200 rounded-sm text-sm md:text-2xl lg:text-xl focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
              ></input>
              <p className="text-red-600 text-start text-xs md:text-2xl lg:text-xl">
                {formik.touched.signature_url && formik.errors.signature_url}
              </p>
            </div>
          </div>
          <div className="lg:!mt-4">
            <label className="text-white text-xs md:text-xl lg:text-xl font-medium">
              Enter Email Template
            </label>
            <EditorProvider>
              <Editor
                name="email_body"
                value={formik.values.email_body}
                onChange={formik.handleChange("email_body")}
                onBlur={formik.handleBlur("email_body")}
                containerProps={{
                  style: {
                    minHeight: "200px",
                    maxHeight: "200px",
                    overflowY: "auto",
                    border: "none",
                    borderRadius: "0.125rem",
                  },
                }}
              >
                <Toolbar>
                  <BtnBold />
                  <BtnItalic />
                  <BtnUnderline />
                  <BtnLink />
                </Toolbar>
              </Editor>
            </EditorProvider>
            <p className="text-red-600 text-start text-xs md:text-2xl lg:text-xl">
              {formik.touched.email_body && formik.errors.email_body}
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center py-3 space-x-8">
          <button
            type="submit"
            className="text-sm  md:text-2xl lg:text-xl px-5 py-1 font-semibold bg-[#303030] text-white rounded-lg shadow-sm"
          >
            {is_edit ? "Update" : "Save"}
          </button>
          <button
            onClick={handleCancel}
            type="button"
            className="text-sm md:text-2xl lg:text-xl  px-5 py-1 font-semibold bg-[#303030] text-white rounded-lg shadow-sm"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default ActivityForm;