import React, { Component } from 'react'
import PrivateRoutes from '../../routes/PrivateRoutes';
import BaseLayout from '../../components/layout/BaseLayout';

class LayoutContainer extends Component {
  handleSidebar = (new_value) => {
    this.props.setIsOpen(new_value);
  }

  handleUsername = (uname) => {
    this.props.setUsername(uname);
  }

  render() {
    const {isOpen,username,is_photo_loading,is_common_loading,is_activity_loading,is_admin , currentPage} = this.props;
    const Modules = [
      {
          id : 'home',
          title : 'Home',
          logo : '',
          link : '/',
      },
      {
          id : 'scan',
          title : 'Scan',
          logo : '',
          link : '/scan',
      },
      {
          id : 'photo-list',
          title : 'Photo List',
          logo : '',
          link : '/photo-list',
      },
      {
          id : 'internal-receiving',
          title : 'Internal Receiving',
          logo : '',
          link : '/internal-receiving',
      },
      
    ];
    if (is_admin){
      Modules.push(
        {
          id : 'manage-activities',
          title : 'Manage Activities',
          logo : '',
          link : '/activities',
        }
      );
    }
    return (
      <div className='w-full h-full'>
        <BaseLayout 
          modules={Modules} 
          ChildComponent={PrivateRoutes} 
          handleSidebar={this.handleSidebar} 
          isOpen={isOpen} 
          username = {username}
          handleUsername = {this.handleUsername}
          is_photo_loading = {is_photo_loading}
          is_common_loading = {is_common_loading}
          is_activity_loading = {is_activity_loading}
          currentPage = {currentPage}
        />
      </div>
    )
  }
}

export default LayoutContainer;