// import { InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
// import { fetchData } from '../../helpers/fetch';

const Header = (props) => {
  const { ToggleSidebar, username, handleUsername ,currentPage} = props;
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  // const {result, error} = useMsalAuthentication(InteractionType.Popup,{
  //   scopes : ["user.read"]
  // })

  const handleSignIn = () => {
    instance.loginRedirect({
      scopes: ['user.read']
    })
  }

  const handleSignOut = () => {
    instance.logoutRedirect();
  }

  useEffect(() => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount) {
      handleUsername(currentAccount.username);
    }

  }, [handleUsername, instance]);
  console.log(window.location.pathname)

  return (
    <>
      <p>{window.location.pathname}</p>
      <header className="z-10 bg-black shadow-md sticky top-0" style={{ display: currentPage.includes('/gallery') ? 'none' : 'flex' }}>
        {/* <header className="z-10 bg-black shadow-md sticky top-0" style={{display :testpath ? 'none':'flex'}}> */}
        {/* <header className="z-10 bg-black shadow-md sticky top-0" > */}
        <div className="site-header top-0 md:sticky fixed bg-black w-full z-50 transition-all duration-300 ease-in-out">
          <div
            className="container py-4 flex flex-col items-center justify-between h-full lg:px-4 md:px-10 px-4 mx-auto text-orange-600">
            <div className="flex items-center justify-between w-full">
              <button className="p-1 -ml-1 mr-1 rounded-md focus:outline-none focus:shadow-outline-orange text-white"
                onClick={ToggleSidebar}
                aria-label="Menu">
                <svg width="30" height="17" viewBox="0 0 30 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="30" height="3" fill="#fff" />
                  <rect y="7" width="30" height="3" fill="#fff" />
                  <rect y="14" width="30" height="3" fill="#fff" />
                </svg>
              </button>
              <div className='flex items-center space-x-1'>
                {
                  isAuthenticated ?
                    <>
                      <p className='md:text-xl lg:text-xl text-xs'>Welcome, {username}</p>
                      <button className='text-white lg:text-xl md:text-xl text-xs' onClick={handleSignOut}>Sign Out</button>
                    </>
                    : <button className='text-white lg:text-xl md:text-xl text-xs' onClick={handleSignIn}>Sign In</button>
                }
              </div>
            </div>
            <div className='w-full flex justify-center items-center lg:mt-4 md:mt-4 mt-0'>
              <a className="flex text-lg font-bold text-gray-600" href="/">
                <img className="lg:w-40 md:w-72 w-full" src="/images/orbus-logo.png" alt="Swagnet Logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header