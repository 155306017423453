import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setIsOpen } from "../../store/common/commonSlice";
import { setUsername } from "../../store/auth/authSlice";

const mapStateToProps = (state) => {
  const {isOpen,is_common_loading,is_admin } = state.common;
  const {username} = state.auth;
  const {is_photo_loading} = state.photo_list;
  const {is_home_loading , currentPage} = state.home;
  const {is_activity_loading} = state.activity;
  return {
    isOpen,
    username,
    is_photo_loading,
    is_home_loading,
    is_common_loading,
    is_activity_loading,
    is_admin,
    currentPage
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setIsOpen,setUsername }, dispatch);

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
