import React, { Component } from 'react'
import Gallery from '../../components/camera/Gallery';
import axios from "axios";
import Uppy from '@uppy/core';
import { v4 as uuid } from "uuid";
import { baseUrl } from '../../helpers/constants';
import { POST_UPLOAD_IMAGE_URL } from '../../store/home/homeConstants';
import { UPDATE_BC_PHOTO_LINK_URL } from '../../store/camera/cameraConstants';

export class GalleryContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      uppy: new Uppy({
        restrictions: {
          minNumberOfFiles: 1,
        }
      })
    }
  }
  componentDidMount() {

    window.addEventListener('popstate', this.handleBackButtonGallery);
    this.state.uppy.on('file-added', async (file) => {
      if (!file.type.startsWith("image/")) {
        alert("File is not an image ")
      }
      else {
        const id_img = uuid();
        // Read the file as a data URL to render it in the gallery
        const dataUrl = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = (err) => reject(err);
          reader.readAsDataURL(file.data);
        });
        const imgData = {
          id: id_img,
          data: dataUrl,
          is_uploaded: false
        }
        this.addGalleryImage(imgData)
      }

    })

    const { currentPage } = this.props;
    if (currentPage !== '/gallery') {
      window.location.replace('/');
    }
    const { order_receiving, is_image_loading } = this.props;
    const { imgGallery } = order_receiving

    if (imgGallery.length === 0) {
      const elements = document.getElementsByClassName('uppy-FileInput-btn');
      const targetElement = Array.from(elements).find(el => el.type === 'button');

      const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };

      targetElement?.addEventListener('click', handleClick);
      targetElement?.click();

      // Cleanup
      return () => {
        targetElement?.removeEventListener('click', handleClick);
      };
    }
  }
  handleBackButtonGallery = (event) => {
    // Redirect the user to the home page when the back button is pressed
    this.props.setPage('/')
    window.location.replace('/');
  };


  removeGalleryImg = (idx) => {
    this.props.removeUploadedGalleryImg(idx)
  }
  addGalleryImage = (imageData) => {
    this.props.setImgGallery(imageData)
  }

  handleCreatePhotoList = async (payload) => {
    let res = await this.props.createPhotoList(payload);
    return res
  }

  uploadFile = async (file, folderName) => {
    const { order_receiving } = this.props;
    const { selectedActivity, referenceNo } = order_receiving;
    const formData = new FormData();
    formData.append('image', file);
    let params = referenceNo.length === 0 ?
      `?activity_id=${selectedActivity}&folder_name=${folderName}` :
      `?activity_id=${selectedActivity}&reference_no=${referenceNo}&folder_name=${folderName}`;

    await axios.post(baseUrl + POST_UPLOAD_IMAGE_URL + params, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        const { key, folder_path } = response.data;
        const payload = { id: key, newStatus: true }   
        this.props.updateGalleryImgStatus(payload);
        this.props.setPhotoLink(folder_path);
      })
      .catch(error => {
        console.error(error); // Handle upload errors
        this.props.setImageLoading(false);
      });
  };

  updateBc = async () => {
    const { order_receiving } = this.props;
    const { selectedUseCase, photoLink, referenceNo } = order_receiving;
    const payload = {
      order_no: referenceNo,
      usecase_id: selectedUseCase,
      photo_link_url: photoLink
    }

    let res = await axios.post(baseUrl + UPDATE_BC_PHOTO_LINK_URL, payload)
      .then(response => {
        return true;
      })
      .catch(error => {
        console.error(error);
        return false;
      });

    return res;
  }

  handleImageLoading = (newVal) => {
    this.props.setImageLoading(newVal);
  }

  handleResetData = () => {
    this.props.setImgGallery(null);
    this.props.setReferenceNo(null);
    this.props.setScanType('');
    this.props.setOrderDetails(null);
    this.props.setSelectedUseCase(null);
    this.props.setSelectedActivity(null);
    this.props.setPhotoLink(null);
  }

  render() {
    const { order_receiving, is_image_loading } = this.props;
    const { imgGallery } = order_receiving
    console.log("order_receiving", order_receiving);
    return <Gallery
      imgGallery={imgGallery}
      removeGalleryImg={this.removeGalleryImg}
      uploadFile={this.uploadFile}
      handleImageLoading={this.handleImageLoading}
      is_image_loading={is_image_loading}
      handleCreatePhotoList={this.handleCreatePhotoList}
      order_receiving={order_receiving}
      handleResetData={this.handleResetData}
      updateBc={this.updateBc}
      uppy={this.state.uppy}
      setPage={this.props.setPage}
    />
  }
}

export default GalleryContainer